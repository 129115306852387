import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUsers = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: 'admin',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      password: Yup.string().required('Password is required'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirmation is required'),
      role: Yup.string().required('Role is required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://el-manasa.mediagrafico.com/api/register', values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });

        if (response.data.message === "User created successfully") {
          toast.success("User created successfully");

          setTimeout(() => {
            navigate('/users');
          }, 2000);
        }
      } catch (error) {
        console.error('Error registering user:', error.response?.data);
        toast.error("Error registering user");
      }
    },
  });

  return (
    <>
      <ToastContainer />

      <form onSubmit={formik.handleSubmit} className="container my-5 w-50">
        <h2>Register a New User</h2>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.name && formik.touched.name ? <div className="text-danger">{formik.errors.name}</div> : null}
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.email && formik.touched.email ? <div className="text-danger">{formik.errors.email}</div> : null}
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.password && formik.touched.password ? <div className="text-danger">{formik.errors.password}</div> : null}
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            name="password_confirmation"
            className="form-control"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.password_confirmation && formik.touched.password_confirmation ? <div className="text-danger">{formik.errors.password_confirmation}</div> : null}
        </div>

        <div className="form-group">
          <label>Role</label>
          <input
            type="text"
            name="role"
            className="form-control"
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.role && formik.touched.role ? <div className="text-danger">{formik.errors.role}</div> : null}
        </div>

        <button type="submit" className="btn btn-primary mt-3">Register</button>
      </form>
    </>
  );
};

export default CreateUsers;
