import axios from 'axios';
import { createContext } from 'react';

export let DashboardContext = createContext();

// =========================================================User=======================================
function getAllUsers() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/users`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching users:", err);
        throw err;
    });
}

function registerUser(userData) {
    const token = localStorage.getItem("userToken");
    return axios.post(`https://el-manasa.mediagrafico.com/api/register`, userData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error registering user:", err);
        throw err;
    });
}

function deleteUser(userId) {
    const token = localStorage.getItem("userToken");
    return axios.delete(`https://el-manasa.mediagrafico.com/api/users/deleteUser/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error deleting user:", err);
        throw err;
    });
}

// Add this function to your DashboardContext

function updateUser(userId, userData) {
    const token = localStorage.getItem("userToken");
    return axios.put(`https://el-manasa.mediagrafico.com/api/users/updateUser/${userId}`, userData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating user:", err);
        throw err;
    });
}


// =====================================================Courses =================================================================
function getAllCourses() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/courses`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching courses:", err);
        throw err;
    });
}

function addCourse(courseData) {
    const token = localStorage.getItem("userToken");
    return axios.post(`https://el-manasa.mediagrafico.com/api/courses/store`, courseData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",  // Specify the content type for form data
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error adding course:", err);
        throw err;
    });
}

function updateCourse(courseId, courseData) {
    const token = localStorage.getItem("userToken");
    return axios.put(`https://el-manasa.mediagrafico.com/api/courses/update/${courseId}`, courseData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating course:", err);
        throw err;
    });
}

function deleteCourse(courseId) {
    const token = localStorage.getItem("userToken");
    return axios.delete(`https://el-manasa.mediagrafico.com/api/courses/delete/${courseId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error deleting course:", err);
        throw err;
    });
}
// =====================================================Categories =================================================================

function getAllCategories() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/categories`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching categories:", err);
        throw err;
    });
}

function updateCategory(categoryId, name) {
    const token = localStorage.getItem("userToken");
    return axios.put(`https://el-manasa.mediagrafico.com/api/categories/update/${categoryId}`, 
    { name }, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating category:", err);
        throw err;
    });
}

function deleteCategory(categoryId) {
    const token = localStorage.getItem("userToken"); 
    return axios.delete(`https://el-manasa.mediagrafico.com/api/categories/delete/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error deleting category:", err);
      throw err;
    });
}

// ===================================================== Subcategories ==================================
function getAllSubcategories() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/subcategories`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching subcategories:", err);
        throw err;
    });
}

function createSubcategory(name, categoryId) {
    const token = localStorage.getItem("userToken");
    return axios.post(
        `https://el-manasa.mediagrafico.com/api/subcategories/create`,
        { name, category_id: categoryId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error creating subcategory:", err);
        throw err;
    });
}

function updateSubcategory(subcategoryId, name, categoryId) {
    const token = localStorage.getItem("userToken");
    return axios.put(
        `https://el-manasa.mediagrafico.com/api/subcategories/update/${subcategoryId}`,
        { name, category_id: categoryId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating subcategory:", err);
        throw err;
    });
}

function deleteSubcategory(subcategoryId) {
    const token = localStorage.getItem("userToken");
    return axios.delete(`https://el-manasa.mediagrafico.com/api/subcategories/delete/${subcategoryId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error deleting subcategory:", err);
        throw err;
    });
}

// ======================================================Lessons=============================================


function getAllLessons() {
    const token = localStorage.getItem("userToken");
    return axios.get(`https://el-manasa.mediagrafico.com/api/lessons`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error fetching lessons:", err);
        throw err;
    });
}

function addLesson(lessonData) {
    const token = localStorage.getItem("userToken");
    return axios.post(`https://el-manasa.mediagrafico.com/api/lessons/store`, lessonData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error adding lesson:", err);
        throw err;
    });
}

function updateLesson(lessonId, lessonData) {
    const token = localStorage.getItem("userToken");
    return axios.put(`https://el-manasa.mediagrafico.com/api/lessons/update/${lessonId}`, lessonData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating lesson:", err);
        throw err;
    });
}
function updateLessonfiles(lessonId, lessonData) {
    const token = localStorage.getItem("userToken");
    return axios.post(`https://el-manasa.mediagrafico.com/api/lessons/update-files/${lessonId}`, lessonData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error updating lesson:", err);
        throw err;
    });
}

function deleteLesson(lessonId) {
    const token = localStorage.getItem("userToken");
    return axios.delete(`https://el-manasa.mediagrafico.com/api/lessons/delete/${lessonId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then((res) => res.data)
    .catch((err) => {
        console.error("Error deleting lesson:", err);
        throw err;
    });
}
















// ======================= DashboardContext Provider ==================================
export default function DashboardContextProvider(props) {
    return (
        <DashboardContext.Provider 
            value={{ 
                getAllUsers, 
                registerUser, 
                deleteUser, 
                updateUser,
                getAllCourses, 
                addCourse,
                updateCourse,
                deleteCourse,
                getAllCategories,
                deleteCategory,
                updateCategory,
                getAllSubcategories,
                createSubcategory,
                deleteSubcategory,
                updateSubcategory,
                getAllLessons,         
                addLesson,             
                updateLesson,         
                deleteLesson,
                updateLessonfiles          
            }}
        >
            {props.children}
        </DashboardContext.Provider>
    );
}
