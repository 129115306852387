import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../Loader/Loader';
import { DashboardContext } from '../../Context/DashboardContext';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './Users.module.css';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  status: Yup.string().required('Status is required'),
});

const Users = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [error, setError] = useState('');
  const { getAllUsers, deleteUser, updateUser } = useContext(DashboardContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllUsersDetails();
  }, []);

  const getAllUsersDetails = async () => {
    try {
      const data = await getAllUsers();
      setUsers(data.users || []);
    } catch (error) {
      console.error('Error fetching users details:', error);
    }
  };

  const removeItem = async (id) => {
    try {
      await deleteUser(id);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const handleEditClick = (user) => {
    setEditingUser(user);
  };

  const handleUpdateSubmit = async (values, { setSubmitting }) => {
    try {
      await updateUser(editingUser.id, values);
      setEditingUser(null);
      getAllUsersDetails();
      setError('');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Error updating user.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Users Page</title>
      </Helmet>

      <div className="container p-4 rounded-5 my-5 bgmaincolor">
        <h1 className={`text-center ${styles.titlecolor} mb-4`}>Students List</h1>
        <div className="d-flex justify-content-start mb-4">
          <Link className="rounded-4 p-2 text-decoration-none backcolor text-white" to={'/createusers'}>Create Users</Link>
        </div>

        {!users.length && <Loader />}

        {/* Update User Form */}
        {editingUser && (
          <div className={`container bgmaincolor ${styles.bgmaincolor} p-4 rounded-3 my-5`} >
            <h2 className=' maincolor'>Update User Info</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <Formik
              initialValues={{
                name: editingUser.name,
                email: editingUser.email,
                password: '',
                password_confirmation: '',
                status: editingUser.status,
              }}
              validationSchema={validationSchema}
              onSubmit={handleUpdateSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field type="text" className="form-control" name="name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field type="email" className="form-control" name="email" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field type="password" className="form-control" name="password" />
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password_confirmation">Confirm Password</label>
                    <Field type="password" className="form-control" name="password_confirmation" />
                    <ErrorMessage name="password_confirmation" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <Field as="select" className="form-control" name="status">
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorMessage name="status" component="div" className="text-danger" />
                  </div>
                  <button type="submit" className="btn" style={{ backgroundColor: '#AC6CFF', color: '#FFF' }} disabled={isSubmitting}>
                    Update User
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {/* Responsive Cards */}
        <div className="row">
          {users.length > 0 ? (
            users.map((user) => (
              <div key={user.id} className="col-md-6 col-lg-4 mb-4">
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title" >{user.name}</h5>
                    <p className="card-text">
                      <strong>Email:</strong> {user.email}
                    </p>
                    <p className="card-text">
                      <strong>Role:</strong> {user.role}
                    </p>
                    <p className="card-text">
                      <strong>Status:</strong> {user.status}
                    </p>
                    <div className="d-flex justify-content-between">
                      <button onClick={() => handleEditClick(user)} className={`${styles.mainBtn} rounded-4 px-3 py-2 border-0`}>
                        Edit
                      </button>
                      <button onClick={() => removeItem(user.id)} className={`${styles.dangerBtn} rounded-4 px-3 py-2 border-0`} >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">
              <p>No users found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
