import React, { useContext, useEffect, useState } from 'react';
import styles from './SubCategory.module.css'; // Import your CSS module
import { DashboardContext } from '../../Context/DashboardContext';
import Loader from '../Loader/Loader';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const SubCategory = () => {
  const [subsCategory, setSubCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [editSubcategoryId, setEditSubcategoryId] = useState(null);
  const [newName, setNewName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { getAllSubcategories, getAllCategories, updateSubcategory, deleteSubcategory } = useContext(DashboardContext);

  async function showAllSubcategories() {
    try {
      setLoading(true);
      const data = await getAllSubcategories();
      setSubCategory(data.subcategories || []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCategories() {
    try {
      const data = await getAllCategories();
      setCategoryOptions(data.categories || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  async function createSubcategory(name, categoryId) {
    const token = localStorage.getItem("userToken");
    try {
      await axios.post(
        `https://el-manasa.mediagrafico.com/api/subcategories/store`,
        { name, category_id: categoryId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Subcategory created");
      showAllSubcategories(); 
      setShowCreateForm(false);
      setNewName(""); 
      setSelectedCategoryId("");
    } catch (error) {
      console.error("Error creating subcategory:", error.response?.data || error.message);
    }
  }

  async function handleUpdateSubcategory() {
    if (editSubcategoryId && newName && selectedCategoryId) {
      try {
        await updateSubcategory(editSubcategoryId, newName, selectedCategoryId);
        console.log("Subcategory updated");
        showAllSubcategories(); 
        setEditSubcategoryId(null); 
      } catch (error) {
        console.error("Error updating subcategory:", error.response?.data || error.message);
      }
    }
  }

  async function handleDeleteSubcategory(subcategoryId) {
    try {
      await deleteSubcategory(subcategoryId);
      console.log("Subcategory deleted");
      showAllSubcategories(); 
    } catch (error) {
      console.error("Error deleting subcategory:", error.response?.data || error.message);
    }
  }

  useEffect(() => {
    showAllSubcategories();
    fetchCategories();
  }, []);

  return (
   <>
   <Helmet>
    <title>SubCategories</title>
   </Helmet>
    <div className={`container bgmaincolor mt-5 py-3 pb-5 px-4 rounded-5 ${styles.container} `}>
      <h1 className='my-4 text-center  maincolor'>SubCategories</h1>
      
      {showCreateForm && (
        <div className={styles.createForm}>
          <h2>Create Subcategory</h2>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="form-control mb-2"
            placeholder="Subcategory Name"
          />
          <select
            className="form-select mb-2"
            value={selectedCategoryId}
            onChange={(e) => setSelectedCategoryId(e.target.value)}
          >
            <option value="">Select Category</option>
            {categoryOptions.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <div className={styles.buttonGroup}>
            <button
              className="btn btn-success "
              onClick={() => createSubcategory(newName, selectedCategoryId)}
            >
              Create Subcategory <i className="fa-solid fa-check"></i>
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      
      {!showCreateForm && (
        <button
          className="rounded-4 p-2 text-decoration-none  backcolor text-white border-0"
          onClick={() => setShowCreateForm(true)}
        >
          Add Subcategory
        </button>
      )}

      <div className="row mt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {subsCategory.length > 0 ? (
              subsCategory.map((subcategory) => (
                <div key={subcategory.id} className="col">
                  <div className={`card shadow-sm border-light rounded ${styles.card}`}>
                    <div className="card-body">
                      {editSubcategoryId === subcategory.id ? (
                        <>
                          <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className="form-control mb-2"
                          />
                          <select
                            className="form-select mb-2"
                            value={selectedCategoryId}
                            onChange={(e) => setSelectedCategoryId(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {categoryOptions.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                          <div className={styles.buttonGroup}>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={handleUpdateSubcategory}
                            >
                              Save <i className="fa-solid fa-check"></i>
                            </button>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => setEditSubcategoryId(null)}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h5 className="card-title">{subcategory.name}</h5>
                          <div className="d-flex justify-content-between">
                            <button
                        className={`${styles.mainBtn} rounded-4 px-3 py-2 border-0 me-3 `}
                              onClick={() => {
                                setEditSubcategoryId(subcategory.id);
                                setNewName(subcategory.name);
                                setSelectedCategoryId(subcategory.category_id);
                              }}
                            >
                              Update <i className="fa-regular fa-pen-to-square"></i>
                            </button>
                            <button
                           className={`${styles.dangerBtn} rounded-4 px-2 py-1 border-0  `}
                              onClick={() => handleDeleteSubcategory(subcategory.id)}
                            >
                              Remove <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Subcategories available</p>
            )}
          </div>
        )}
      </div>
    </div>
   </>
  );
};

export default SubCategory;
