import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const CreateSub = ({ initialData = null, onClose }) => {
  const navigate = useNavigate();
  const isUpdating = !!initialData; 

  const formik = useFormik({
    initialValues: {
      name: initialData?.name || '',
      category_id: initialData?.category_id || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      category_id: Yup.string().required('Category ID is required')
    }),
    enableReinitialize: true, 
    onSubmit: async (values) => {
      try {
        if (isUpdating) {
          await axios.put(
            `https://el-manasa.mediagrafico.com/api/subcategories/update/${initialData.id}`, 
            values, 
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              },
            }
          );
          toast.success('Subcategory updated successfully');
        } else {
         
          await axios.post(
            'https://el-manasa.mediagrafico.com/api/subcategories/store', 
            values, 
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              },
            }
          );
          toast.success('Subcategory created successfully');
        }
        setTimeout(() => {
          navigate('/subcategory');
        }, 2000);
      } catch (error) {
        console.error('Error creating/updating subcategory:', error.response?.data || error.message);
        toast.error('Error creating/updating subcategory');
      }
    }
  });

  return (
    <>
    <Helmet>
      <title></title>
    </Helmet>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit} className="container my-5 w-50">
        <h2>{isUpdating ? 'Update Subcategory' : 'Create a New Subcategory'}</h2>

        <div className="form-group">
          <label>Name</label>
          <input 
            type="text" 
            name="name" 
            className="form-control" 
            value={formik.values.name} 
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </div>

        <div className="form-group mt-3">
          <label>Category ID</label>
          <input 
            type="text" 
            name="category_id" 
            className="form-control" 
            value={formik.values.category_id} 
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
          />
          {formik.errors.category_id && formik.touched.category_id ? (
            <div className="text-danger">{formik.errors.category_id}</div>
          ) : null}
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          {isUpdating ? 'Update' : 'Create'}
        </button>
      </form>
    </>
  );
};

export default CreateSub;
