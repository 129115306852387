import React, { useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/logo.png';
import styles from './Navbar.module.css';
import { TokenContext } from '../../Context/Token';

const Navbar = () => {
  let { token, setToken } = useContext(TokenContext)
  let navigate = useNavigate()


  function LogOut() {
    localStorage.removeItem("userToken")
    setToken(null)
    navigate("/login")
  }

  
    const scrollToTop = () => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    };


  return (
    <>
      <nav className={`navbar navbar-expand-lg ${styles.navbar}`}>
        <div className="container">
          <NavLink className="navbar-brand  bg-transparent  text-white" onClick={scrollToTop} to={'/users'}>

            Dashboard
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {token ? <ul className="navbar-nav ms-auto  mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link me-1 p-2 text-white " activeClassName="active"
                  onClick={scrollToTop}
                  aria-current="page"
                  to={'/users'}
                >
                  <i className="fa-solid fa-graduation-cap me-2"></i>Students
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link me-1   text-white " activeClassName="active"
                  onClick={scrollToTop}
                  to={'/categories'}
                >
                  Categories
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link me-1 p-2 text-white " activeClassName="active"
                  onClick={scrollToTop}
                  to={'/subcategory'}
                >
                  Sub Category
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link me-1 p-2 text-white " activeClassName="active"
                   onClick={scrollToTop}
                  to={'/courses'}
                >
                  Courses
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link me-1 p-2 text-white " activeClassName="active"
                  onClick={scrollToTop}
                  to={'/lessons'}
                >
                  Lessons
                </NavLink>
              </li>
            </ul> : null}

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {token ? <>
               
                <li className="nav-item">
                  <button className="nav-link p-2 me-1 text-white " activeClassName="active" onClick={LogOut}>LogOut<i className="fa-solid ms-2 fa-right-from-bracket"></i></button>
                </li>

              </> :

                <>
                  <li className="nav-item">
                    <NavLink className="nav-link p-2 me-1 py-2 px-4 text-white" activeClassName="active" to={'login'}>login</NavLink>
                  </li>
                </>}
            </ul>
          </div>
        </div>
      </nav>
    </>

  );
};

export default Navbar;
