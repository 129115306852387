import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { TokenContext } from '../../Context/Token';
import { Helmet } from 'react-helmet';
import styles from './Login.module.css';
import logo from '../../Assets/images/logo.jpg';

const Login = () => {
  const [errMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let { setToken } = useContext(TokenContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required("Email is required"),
    password: Yup.string()
      .matches(/^[a-z0-9]{3,8}$/, "Password must be 6-9 characters, start with a letter, and contain only letters and numbers")
      .required("Password is required"),
  });

  async function callLogin(reqBody) {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const { data } = await axios.post(`https://el-manasa.mediagrafico.com/api/login`, reqBody);
      console.log(data);

      if (data.message === "Logged in successfully") {
        const { access_token, token_type } = data; 
        localStorage.setItem('userToken', `${token_type} ${access_token}`); 
        setToken(`${token_type} ${access_token}`); 
        navigate("/users"); 
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err.response.data.message);
    }
  }

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: callLogin
  });

  useEffect(() => {
  }, []);

  return (
    <>
      <Helmet>
        <title>Login Page</title>
      </Helmet>
      <div className="container-fluid d-flex flex-column align-items-center justify-content-center my-5">
        <div className={`bgmaincolor p-4 rounded-5 ${styles.loginContainer}`} style={{ maxWidth: '500px', width: '100%' }}>
          <div className="title d-flex justify-content-between align-items-center pt-2">
            <h2 className='mt-5 maincolor fw-bold text-center w-100'>Login now</h2>
            <img src={logo} className='rounded-4 mt-5' width={100} height={100} alt="Logo" />
          </div>
          {errMessage && <div className='alert alert-danger'>{errMessage}</div>}
          
          <form className={`p-3 ${styles.mycard}`} onSubmit={loginForm.handleSubmit}>
            <div className="form-group my-3">
              <label className="mb-1" htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                onBlur={loginForm.handleBlur}
                className="form-control mb-3"
                value={loginForm.values.email}
                onChange={loginForm.handleChange}
              />
              {loginForm.errors.email && loginForm.touched.email ? (
                <div className='alert alert-danger'>{loginForm.errors.email}</div>
              ) : null}
            </div>
            
            <div className="form-group my-3">
              <label className="mb-1" htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                onBlur={loginForm.handleBlur}
                className="form-control mb-3"
                value={loginForm.values.password}
                onChange={loginForm.handleChange}
              />
              {loginForm.errors.password && loginForm.touched.password ? (
                <div className='alert alert-danger'>{loginForm.errors.password}</div>
              ) : null}
            </div>
            
            <button
              type="submit"
              className={`btn btn-lg border-0 backcolor text-white d-block ms-auto ${styles.loginButton}`}
              disabled={!(loginForm.isValid && loginForm.dirty)}
            >
              {isLoading ? <i className='fa fa-spinner fa-spin'></i> : 'Login now'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
