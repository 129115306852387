import React from 'react';
import styles from './Profile.module.css';
import { Helmet } from 'react-helmet';

const Profile = () => {
  return(<>
    <Helmet>
    <title>Profile</title>
  </Helmet>
    <div className={styles.Profile}>
        Profile Component
  </div>
  </>)
};

export default Profile;
