import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../Context/DashboardContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import styles from './Courses.module.css'; // Import custom CSS file
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import Loader from '../Loader/Loader';
import { Helmet } from 'react-helmet';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [newCourse, setNewCourse] = useState({
    subcategory_id: '',
    title: '',
    description: '',
    price: '',
    image: null,
  });
  const [editingCourse, setEditingCourse] = useState(null);
  const [updateCourseData, setUpdateCourseData] = useState({
    subcategory_id: '',
    title: '',
    description: '',
    price: '',
  });
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getAllCourses, getAllSubcategories, addCourse, updateCourse, deleteCourse } = useContext(DashboardContext);

  useEffect(() => {
    const fetchCoursesAndSubcategories = async () => {
      try {
        setLoading(true);
        const courseData = await getAllCourses();
        setCourses(courseData.courses || []);
        const subcategoryData = await getAllSubcategories();
        setSubcategories(subcategoryData.subcategories || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoursesAndSubcategories();
  }, [getAllCourses, getAllSubcategories]);

  const handleNewCourseChange = (e) => {
    const { name, value, type, files } = e.target;
    setNewCourse({
      ...newCourse,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const validateForm = (courseData) => {
    const errors = {};
    if (!courseData.subcategory_id) errors.subcategory_id = 'Subcategory ID is required.';
    if (!courseData.title) errors.title = 'Title is required.';
    if (!courseData.description) errors.description = 'Description is required.';
    if (!courseData.price) errors.price = 'Price is required.';
    return errors;
  };

  const handleAddCourse = async (e) => {
    e.preventDefault();
    const errors = validateForm(newCourse);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    const formData = new FormData();
    for (const key in newCourse) {
      formData.append(key, newCourse[key]);
    }
    try {
      await addCourse(formData);
      setNewCourse({
        subcategory_id: '',
        title: '',
        description: '',
        price: '',
        image: null,
      });
      setShowCreateForm(false);
      setShowUpdateForm(false);
      const data = await getAllCourses();
      setCourses(data.courses || []);
      setFieldErrors({});
      toast.success('Course added successfully!');
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setFieldErrors(error.response.data.errors);
      } else {
        setError(error.response ? error.response.data.message : 'Error adding course.');
        toast.error('Error adding course.');
      }
    }
  };

  const handleEditCourse = (course) => {
    setEditingCourse(course);
    setUpdateCourseData({
      subcategory_id: course.subcategory_id || '',
      title: course.title || '',
      description: course.description || '',
      price: course.price || '',
    });
    setShowUpdateForm(true);
    setShowCreateForm(false);
  };

  const handleUpdateCourseChange = (e) => {
    const { name, value } = e.target;
    setUpdateCourseData({
      ...updateCourseData,
      [name]: value,
    });
  };

  const handleUpdateCourse = async () => {
    const errors = validateForm(updateCourseData);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    if (editingCourse) {
      const formData = new FormData();
      for (const key in updateCourseData) {
        formData.append(key, updateCourseData[key]);
      }
      try {
        await updateCourse(editingCourse.id, formData);
        setEditingCourse(null);
        setShowUpdateForm(false);
        const data = await getAllCourses();
        setCourses(data.courses || []);
        setFieldErrors({});
        toast.success('Course updated successfully!');
      } catch (error) {
        if (error.response && error.response.data.errors) {
          setFieldErrors(error.response.data.errors);
        } else {
          setError(error.response ? error.response.data.message : 'Error updating course.');
          toast.error('Error updating course.');
        }
      }
    }
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await deleteCourse(courseId);
      const data = await getAllCourses();
      setCourses(data.courses || []);
      toast.success('Course deleted successfully!');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Error deleting course.');
      toast.error('Error deleting course.');
    }
  };

  return (
  <>
    <Helmet>
    <title> Courses</title>
    </Helmet>
    <section className={`container my-5 py-5 px-4 pb-5 bgmaincolor  rounded-5 ${styles.coursesContainer}`}>
      <h1 className="mb-4 text-center maincolor">Courses</h1>

      {loading ? <Loader /> : (
        <>
          <div className="text-left mb-4">
            <button
              className="rounded-4 p-2 text-decoration-none  backcolor text-white border-0"
              onClick={() => {
                setShowCreateForm(true);
                setShowUpdateForm(false);
              }}
            >
              Create Course
            </button>
          </div>

          {showCreateForm && (
            <div className="mb-4">
              <h2>Add New Course</h2>
              {error && <div className="alert alert-danger">{error}</div>}
              {Object.keys(fieldErrors).length > 0 && (
                <div className="alert alert-danger">
                  <ul>
                    {Object.entries(fieldErrors).map(([field, message]) =>
                      <li key={field}>{message}</li>
                    )}
                  </ul>
                </div>
              )}
              <form onSubmit={handleAddCourse}>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="subcategory_id"
                    value={newCourse.subcategory_id}
                    onChange={handleNewCourseChange}
                  >
                    <option value="">Select Subcategory</option>
                    {subcategories.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                  {fieldErrors.subcategory_id && <div className="text-danger">{fieldErrors.subcategory_id}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Title"
                    value={newCourse.title}
                    onChange={handleNewCourseChange}
                  />
                  {fieldErrors.title && <div className="text-danger">{fieldErrors.title}</div>}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="description"
                    placeholder="Description"
                    value={newCourse.description}
                    onChange={handleNewCourseChange}
                  />
                  {fieldErrors.description && <div className="text-danger">{fieldErrors.description}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    placeholder="Price"
                    value={newCourse.price}
                    onChange={handleNewCourseChange}
                  />
                  {fieldErrors.price && <div className="text-danger">{fieldErrors.price}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleNewCourseChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary">Add Course</button>
                <button
                  type="button"
                  className="btn btn-secondary ml-2"
                  onClick={() => setShowCreateForm(false)}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}

          {showUpdateForm && editingCourse && (
            <div className="mb-4">
              <h2>Update Course</h2>
              {error && <div className="alert alert-danger">{error}</div>}
              {Object.keys(fieldErrors).length > 0 && (
                <div className="alert alert-danger">
                  <ul>
                    {Object.entries(fieldErrors).map(([field, message]) =>
                      <li key={field}>{message}</li>
                    )}
                  </ul>
                </div>
              )}
              <form onSubmit={(e) => { e.preventDefault(); handleUpdateCourse(); }}>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="subcategory_id"
                    value={updateCourseData.subcategory_id}
                    onChange={handleUpdateCourseChange}
                  >
                    <option value="">Select Subcategory</option>
                    {subcategories.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                  {fieldErrors.subcategory_id && <div className="text-danger">{fieldErrors.subcategory_id}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Title"
                    value={updateCourseData.title}
                    onChange={handleUpdateCourseChange}
                  />
                  {fieldErrors.title && <div className="text-danger">{fieldErrors.title}</div>}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="description"
                    placeholder="Description"
                    value={updateCourseData.description}
                    onChange={handleUpdateCourseChange}
                  />
                  {fieldErrors.description && <div className="text-danger">{fieldErrors.description}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    placeholder="Price"
                    value={updateCourseData.price}
                    onChange={handleUpdateCourseChange}
                  />
                  {fieldErrors.price && <div className="text-danger">{fieldErrors.price}</div>}
                </div>
                <button type="submit" className="btn btn-success">Update Course<i className="fa-solid fa-check"></i></button>
                <button
                  type="button"
                  className="btn btn-secondary ml-2"
                  onClick={() => setShowUpdateForm(false)}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}

          <div className="row">
            {courses.map((course) => (
              <div className="col-md-4 mb-4" key={course.id}>
                <div className={`card ${styles.courseCard}`}>
                  <img
                    src={"https://el-manasa.mediagrafico.com/"+course.image}
                    className="card-img-top"
                    alt={course.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{course.title}</h5>
                    <p className="card-text">{course.description}</p>
                  
                    <p className="card-text"><strong>Price:</strong> ${course.price}</p>
                    <button className={`${styles.mainBtn} rounded-4 px-3 py-2 border-0 me-3 `} onClick={() => handleEditCourse(course)}>Edit</button>
                    <button className={`border-0 p-2 rounded-3 ${styles.dangerBtn}`} onClick={() => handleDeleteCourse(course.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <ToastContainer />
    </section>
  </>
  );
};

export default Courses;
