import React from 'react';
import styles from './UpdateUsers.module.css';

const UpdateUsers = () => {
  return(<>
    <div className={styles.UpdateUsers}>
        UpdateUsers Component
  </div>
  </>)
};

export default UpdateUsers;
