import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../Context/DashboardContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Lessons.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import { Helmet } from 'react-helmet';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const Lessons = () => {
  const [lessons, setLessons] = useState([]);
  const [courses, setCourses] = useState([]);
  const [newLesson, setNewLesson] = useState({ course_id: '', title: '', content: '' });
  const [editingLesson, setEditingLesson] = useState(null);
  const [updateLessonData, setUpdateLessonData] = useState({ course_id: '', title: '', content: '' , files: ''});
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [loadingLessons, setLoadingLessons] = useState(true);
  const [loadingCourses, setLoadingCourses] = useState(true);

  const { getAllLessons, getAllCourses, addLesson, updateLesson, deleteLesson , updateLessonfiles } = useContext(DashboardContext);
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: 100000000, // 100MB for video
      allowedFileTypes: ['video/*', '.pdf'], // Video and PDF allowed
      maxNumberOfFiles: 2,
    },
    autoProceed: false,
  });
  const uppyUpdate = new Uppy({
    restrictions: {
      maxFileSize: 100000000, // 100MB for video
      allowedFileTypes: ['video/*', '.pdf'], // Video and PDF allowed
      maxNumberOfFiles: 2,
    },
    autoProceed: false,
  });

  useEffect(() => {
    return () => {
      uppy.cancelAll();
    };
  }, [uppy]);
  useEffect(() => {
    return () => {
      uppyUpdate.cancelAll();
    };
  }, [uppyUpdate]);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const data = await getAllLessons();
        setLessons(data.lessons || []);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      } finally {
        setLoadingLessons(false);
      }
    };
    fetchLessons();
  }, [getAllLessons]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const data = await getAllCourses();
        setCourses(data.courses || []);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoadingCourses(false);
      }
    };
    fetchCourses();
  }, [getAllCourses]);

  const handleNewLessonChange = (e) => {
    const { name, value } = e.target;
    setNewLesson({
      ...newLesson,
      [name]: value,
    });
  };

  const validateForm = (lessonData) => {
    const errors = {};
    if (!lessonData.course_id) errors.course_id = 'Course ID is required.';
    if (!lessonData.title) errors.title = 'Title is required.';
    if (!lessonData.content) errors.content = 'Content is required.';
    return errors;
  };

  const handleAddLesson = async (e) => {
    e.preventDefault();

    const errors = validateForm(newLesson);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    try {
      const selectedCourse = courses.find(course => course.id === newLesson.course_id);
      const lessonData = { ...newLesson, course_title: selectedCourse ? selectedCourse.title : '' };
      const submissionData = new FormData();
      submissionData.append('course_id', newLesson.course_id);
      submissionData.append('title', lessonData.title);
      submissionData.append('content', lessonData.content);
  
      // Add selected files from Uppy
      uppy.getFiles().forEach(file => {
        submissionData.append('files[]', file.data); // Assuming multiple files
      });

      await addLesson(submissionData);
      setNewLesson({ course_id: '', title: '', content: '' });
      setShowCreateForm(false);
      setShowUpdateForm(false);
      const data = await getAllLessons();
      setLessons(data.lessons || []);
      setFieldErrors({});
      toast.success('Lesson added successfully!');
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setFieldErrors(error.response.data.errors);
      } else {
        setError(error.response ? error.response.data.message : 'Error adding lesson.');
        toast.error('Error adding lesson.');
      }
    }
  };

  const handleEditLesson = (lesson) => {
    setEditingLesson(lesson);
    setUpdateLessonData({
      course_id: lesson.course_id || '',
      title: lesson.title || '',
      content: lesson.content || '',
    });
    setShowUpdateForm(true);
    setShowCreateForm(false);
  };

  const handleUpdateLessonChange = (e) => {
    const { name, value } = e.target;
    setUpdateLessonData({
      ...updateLessonData,
      [name]: value,
    });
  };

  const handleUpdateLesson = async (e) => {
    e.preventDefault();

    const errors = validateForm(updateLessonData);
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    if (editingLesson) {
      try {
        const selectedCourse = courses.find(course => course.id === updateLessonData.course_id);
        const updatedLessonData = { ...updateLessonData, course_title: selectedCourse ? selectedCourse.title : '' };
        const submissionData2 = new FormData();
        submissionData2.append('course_id', updateLessonData.course_id);
        submissionData2.append('title', updateLessonData.title);
        submissionData2.append('content', updateLessonData.content); 
        const filesData = new FormData();
        uppyUpdate.getFiles().forEach(file => {
          filesData.append('files[]', file.data); // Assuming multiple files
        });
        setLoadingLessons(true);
        await updateLessonfiles(editingLesson.id, filesData);
        await updateLesson(editingLesson.id, submissionData2); 

        setEditingLesson(null);
        setShowUpdateForm(false);
        const data = await getAllLessons();
        setLessons(data.lessons || []);
        setFieldErrors({});
        setLoadingLessons(false);
        toast.success('Lesson updated successfully!');
      } catch (error) {
        console.log(error);
        
        if (error.response && error.response.data.errors) {
          setFieldErrors(error.response.data.errors);
        } else {
          setError(error.response ? error.response.data.message : 'Error updating lesson.');
          toast.error('Error updating lesson.');
        }
      }
    }
  };

  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteLesson(lessonId);
      const data = await getAllLessons();
      setLessons(data.lessons || []);
      toast.success('Lesson deleted successfully!');
    } catch (error) {
      setError(error.response ? error.response.data.message : 'Error deleting lesson.');
      toast.error('Error deleting lesson.');
    }
  };

  return (
    <>
    
    
      <Helmet>
      <title>Lessons</title>
    </Helmet>
    <>
    <Helmet>
      <title>Lessons</title>
    </Helmet>
    <section className={`container my-5 bgmaincolor pt-5 px-3 pb-3 rounded-5 ${styles.lessonsContainer}`}>
      <h1 className="mb-4  text-center maincolor">Lessons</h1>

      <div className="mb-4">
        {showCreateForm && (
          <div className="mt-4">
            <h2 className=' maincolor'>Create Lesson</h2>
            <form onSubmit={handleAddLesson}>
              <div className="mb-3">
                <label htmlFor="course_id" className="form-label">Course ID</label>
                <select
                  id="course_id"
                  name="course_id"
                  className={`form-select ${fieldErrors.course_id ? 'is-invalid' : ''}`}
                  value={newLesson.course_id}
                  onChange={handleNewLessonChange}
                >
                  <option value="">Select a course</option>
                  {courses.map(course => (
                    <option key={course.id} value={course.id}>{course.title}</option>
                  ))}
                </select>
                {fieldErrors.course_id && <div className="invalid-feedback">{fieldErrors.course_id}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className={`form-control ${fieldErrors.title ? 'is-invalid' : ''}`}
                  value={newLesson.title}
                  onChange={handleNewLessonChange}
                />
                {fieldErrors.title && <div className="invalid-feedback">{fieldErrors.title}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Content</label>
                <textarea
                  id="content"
                  name="content"
                  className={`form-control ${fieldErrors.content ? 'is-invalid' : ''}`}
                  rows="3"
                  value={newLesson.content}
                  onChange={handleNewLessonChange}
                ></textarea>
                {fieldErrors.content && <div className="invalid-feedback">{fieldErrors.content}</div>}
              </div>
              <div className="mb-3">
                <Dashboard
                  uppy={uppy}
                  plugins={['XHRUpload']}
                  width="100%"
                  height="400px"
                />
              </div>
              <button type="submit" className="btn btn-primary">Add Lesson</button>
            </form>
          </div>
        )}

        {showUpdateForm && editingLesson && (
          <div className="mt-4">
            <h2 className=' maincolor'> Update Lesson</h2>
            <form onSubmit={handleUpdateLesson}>
              <div className="mb-3">
                <label htmlFor="course_id" className="form-label">Course ID</label>
                <select
                  id="course_id"
                  name="course_id"
                  className={`form-select ${fieldErrors.course_id ? 'is-invalid' : ''}`}
                  value={updateLessonData.course_id}
                  onChange={handleUpdateLessonChange}
                >
                  <option value="">Select a course</option>
                  {courses.map(course => (
                    <option key={course.id} value={course.id}>{course.title}</option>
                  ))}
                </select>
                {fieldErrors.course_id && <div className="invalid-feedback">{fieldErrors.course_id}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className={`form-control ${fieldErrors.title ? 'is-invalid' : ''}`}
                  value={updateLessonData.title}
                  onChange={handleUpdateLessonChange}
                />
                {fieldErrors.title && <div className="invalid-feedback">{fieldErrors.title}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Content</label>
                <textarea
                  id="content"
                  name="content"
                  className={`form-control ${fieldErrors.content ? 'is-invalid' : ''}`}
                  rows="3"
                  value={updateLessonData.content}
                  onChange={handleUpdateLessonChange}
                ></textarea>
                {fieldErrors.content && <div className="invalid-feedback">{fieldErrors.content}</div>}
              </div>
              <div className="mb-3">
                <Dashboard
                  uppy={uppyUpdate}
                  plugins={['XHRUpload']}
                  width="100%"
                  height="400px"
                />
              </div>
              <button type="submit" className="btn btn-success mb-5">Update Lesson <i className="fa-solid fa-check"></i></button>
            </form>
          </div>
        )}

        {/* Button to show create form */}
        <div className="text-left mb-4">
          <button
            className="rounded-4 p-2 text-decoration-none  backcolor text-white border-0"
            onClick={() => {
              setShowCreateForm(true);
              setShowUpdateForm(false);
            }}
          >
            Create Lesson
          </button>
        </div>
      </div>

      {/* Lessons List */}
      {loadingLessons || loadingCourses ? (
        <Loader />
      ) : (
        <div className="row">
          {lessons.map((lesson) => (
            <div key={lesson.id} className={`col-md-4 mb-4 ${styles.cardContainer}`}>
              <div className="card">
                <div className="card-body">
                  <h5 className={`card-title ${styles.cardTitle}`}>{lesson.title}</h5>
                  <h6 className={`card-subtitle mb-2 text-muted ${styles.cardSubtitle}`}>{lesson.course_title}</h6>
                  <p className={`card-text ${styles.cardText}`}>{lesson.content}</p>
                  {lesson.files && lesson.files.map((file, index) => (
                    <div key={index} className={styles.videoContainer}>
                      {file.type === 'video' ? (
                        <video controls>
                          <source src={file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <a href={file.url} target="_blank" rel="noopener noreferrer" className={styles.pdfIcon}>
                          <i className="bi bi-file-earmark-pdf"></i> PDF
                        </a>
                      )}
                    </div>
                  ))}
                  <button
                    className={`${styles.mainBtn} rounded-4 px-3 py-2 border-0 me-3 `}
                    onClick={() => handleEditLesson(lesson)}
                  >
                    Edit
                  </button>
                  <button
                    className={`border-0 p-2 rounded-3 ${styles.dangerBtn}`}
                    onClick={() => handleDeleteLesson(lesson.id)}
                  >
                    Delete

                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <ToastContainer />
    </section>
  </>
);

    </>
  );
};

export default Lessons;
