import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Categories from './Components/Categories/Categories'
import Login from './Components/Login/Login'
import LayOut from './Components/LayOut/LayOut'
import  Users    from './Components/Users/Users'
import  Courses    from './Components/Courses/Courses'
import  UpdateUsers    from './Components/UpdateUsers/UpdateUsers'
import  CreateUsers    from './Components/CreateUsers/CreateUsers'
import  SubCategory    from './Components/SubCategory/SubCategory'
import  Lessons    from './Components/Lessons/Lessons'
import  Profile    from './Components/Profile/Profile'

import TokenContextProvider, { TokenContext } from './Context/Token';
import { useContext, useEffect } from 'react';
import ProtectedRoutes from './Components/ProtectedRoutes/ProtectedRoutes';
import ProtectedRoutesX from './Components/ProtectedRoutesX/ProtectedRoutesX';
import CreateSub from './Components/CreateSub/CreateSub';




function App() {
  let { setToken } = useContext(TokenContext)
  const routes = createBrowserRouter([
    {
      path: "", element: <LayOut />, children: [
        { path: "/", element:<ProtectedRoutes><Users /></ProtectedRoutes>  },
        { path: "users", element: <ProtectedRoutes><Users /></ProtectedRoutes>      },
        { path: "profile", element: <ProtectedRoutes><Profile /></ProtectedRoutes>      },

        { path: "categories", element: <ProtectedRoutes><Categories /></ProtectedRoutes>      },
        { path: "subcategory", element: <ProtectedRoutes><SubCategory /></ProtectedRoutes>      },
        { path: "createsub", element: <ProtectedRoutes><CreateSub /></ProtectedRoutes>      },
        
        { path: "lessons", element: <ProtectedRoutes><Lessons /></ProtectedRoutes>      },

        { path: "courses", element: <ProtectedRoutes>   <Courses /> </ProtectedRoutes>  },
        { path: "updateusers", element:  <ProtectedRoutes><UpdateUsers/></ProtectedRoutes>     },
        { path: "createusers", element: <ProtectedRoutes><CreateUsers/></ProtectedRoutes>      },
        { path: "*", element: <ProtectedRoutes><Users /></ProtectedRoutes> },
        { path: "login", element:<ProtectedRoutesX><Login /></ProtectedRoutesX>   }
      

      ]
    }
  ])
  useEffect(() => {
    if (localStorage.getItem("userToken") != null) {
      setToken(localStorage.getItem("userToken"))
    }
  }, [])
  return (
    <TokenContextProvider>
    <RouterProvider router={routes}></RouterProvider>

    </TokenContextProvider>


  );
}

export default App;
