import React, { useContext, useEffect, useState } from 'react';
import styles from './Categories.module.css';
import { DashboardContext } from '../../Context/DashboardContext';
import Loader from '../Loader/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const { getAllCategories, updateCategory, deleteCategory } = useContext(DashboardContext);
  const [isLoading, setLoading] = useState(true);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [newName, setNewName] = useState(""); 
  const [showCreateForm, setShowCreateForm] = useState(false); 

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required')
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://el-manasa.mediagrafico.com/api/categories/store', values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });

        toast.success("Category created successfully");
        setTimeout(() => {
          getCategories(); 
          setShowCreateForm(false); 
        }, 2000); 
        
      } catch (error) {
        toast.error("Error creating category");
      }
    }
  });

  async function getCategories() {
    try {
      setLoading(true);
      const data = await getAllCategories();
      setCategories(data.categories || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCategoryUpdate(categoryId) {
    try {
      await updateCategory(categoryId, newName);
      await getCategories(); 
      setEditCategoryId(null);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  }

  async function handleDeleteCategory(categoryId) {
    try {
      await deleteCategory(categoryId);
      await getCategories(); 
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  }

  useEffect(() => {

     
 
    getCategories();
  }, []);

  return (
   <>
     <Helmet>
      <title>Categories </title>
      </Helmet>
    <div className={`container mt-5 pb-5 py-3 px-4  rounded-5 ${styles.container}`}>
      <ToastContainer />
      <h1 className={`my-3 ${styles.titleColor} text-center`}>Categories</h1>
      {!showCreateForm && (
        <button className={`rounded-4 p-2 text-decoration-none  border-0 backcolor text-white`} onClick={() => setShowCreateForm(true)}>
          Create Category
        </button>
      )}

      {showCreateForm && (
        <form onSubmit={formik.handleSubmit} className="my-4">
          <h2 className={styles.titleColor}>Create a New Category</h2>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              id="name"
              type="text" 
              name="name" 
              className="form-control" 
              value={formik.values.name} 
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
            />
            {formik.errors.name && formik.touched.name ? <div className="text-danger">{formik.errors.name}</div> : null}
          </div>
          <div className={styles.buttonGroup}>
            <button type="submit" className={`btn btn-primary me-2 ${styles.mainBtn} p-2`}>Create</button>
            <button type="button" className={`btn btn-secondary ${styles.secondaryBtn} p-2 rounded-5`} onClick={() => setShowCreateForm(false)}>Cancel</button>
          </div>
        </form>
      )}

      <div className="row">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {categories.length > 0 ? (
              categories.map((category) => (
                <div key={category.id} className="col">
                  <div className={`card shadow-sm border-light rounded ${styles.cardBg}`}>
                    <div className="card-body">
                      {editCategoryId === category.id ? (
                        <>
                          <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className="form-control mb-2"
                          />
                          <button
                            className={`btn btn-success btn-sm me-2 `}
                            onClick={() => handleCategoryUpdate(category.id)}
                          >
                            Save <i className="fa-solid fa-check"></i>
                          </button>
                          <button
                            className={`btn btn-secondary btn-sm `}
                            onClick={() => setEditCategoryId(null)}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <h5 className={styles.titleColor}>{category.name}</h5>
                          <div className="d-flex justify-content-between">
                            <button
                              className={`btn btn-info btn-sm me-2 ${styles.mainBtn}`}
                              onClick={() => {
                                setEditCategoryId(category.id);
                                setNewName(category.name);
                              }}
                            >
                              Update <i className="fa-regular fa-pen-to-square"></i>
                            </button>
                            <button
                              className={`btn btn-danger btn-sm ${styles.dangerBtn}`}
                              onClick={() => handleDeleteCategory(category.id)}
                            >
                              Remove <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No categories available</p>
            )}
          </div>
        )}
      </div>
    </div>
   </>
  );
};

export default Categories;
